import {Box, Grid, Stack,} from "@mui/material";
import React, {useEffect, useState} from "react";
import SearchButtonGroup from "../../component/board/SearchButtonGroup";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment";
import {ChartSeriesType, Hscm9000Type, SearchDateTimeRangeType, WrapIdType, XyDataType} from "../../@types";
import {
  convertMoment,
  exportExcel,
  formatDate,
  getDateTimeRange,
  makeToast,
  wrapIdField
} from "../../common/CommonUtil";
import Hscm9000Api from "../../api/device/Hscm9000Api";
import {GridColDef} from "@mui/x-data-grid";
import DefaultDataGrid from "../../component/board/DefaultDataGrid";
import LineChart from "../../component/chart/LineChart";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../state";

type Hscm9000PageProps = {
  tag: string
}

export default function Hscm9000Page(props: Hscm9000PageProps) {
  const setLoading = useSetRecoilState(loadingState);
  const hscm9000Api = Hscm9000Api();

  const [dateTimeRange, setDateTimeRagne]
    = useState<SearchDateTimeRangeType>(getDateTimeRange('1d'))

  const [hscm9000DataRows, setHscm9000DataRows] = useState<WrapIdType<Hscm9000Type>[]>([])

  // Chart State
  const [calInsDataset, setCalInsDataset] = useState<ChartSeriesType>([])
  const [flowInsDataset, setFlowInsDataset] = useState<ChartSeriesType>([])
  const [supplyTempInsDataset, setSupplyTempInsDataset] = useState<ChartSeriesType>([])
  const [returnTempInsDataset, setReturnTempInsDataset] = useState<ChartSeriesType>([])
  const [pressInsDataset, setPressInsDataset] = useState<ChartSeriesType>([])

  const supplyTempText = props.tag === "LOTTE_GUNSAN_HSCM9000_1" ? "공급온도 (주정동 -> 냉동기)" : "공급온도"
  const returnTempText = props.tag === "LOTTE_GUNSAN_HSCM9000_1" ? "회수온도 (냉동기 -> 주정동)" : "회수온도"


  const hscm9000DataColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align: 'center',
      width: 80
    },
    {
      field: 'rtc',
      headerName: '시간',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'calIns',
      headerName: '순간열량 Gcal',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'calSum',
      headerName: '적산열량 Gcal',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'flowIns',
      headerName: '순간유량 m3/h',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'flowSum',
      headerName: '적산유량 m3/h',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'supplyTempIns',
      headerName: `${supplyTempText}`,
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'returnTempIns',
      headerName: `${returnTempText}`,
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
  ]

  useEffect(() => {
    search()
  }, [])

  useEffect(() => {
    search()
  }, [props.tag])

  // 조회조건 이벤트
  const handleDateTimeOnChange = (target: "start" | "end", value: moment.Moment | null) => {
    if (value === null) {
      return
    }

    if (target === "start") {
      setDateTimeRagne({...dateTimeRange, start: value})
    } else {
      setDateTimeRagne({...dateTimeRange, end: value})
    }
  }

  // 조회
  const search = async () => {
    // Validation
    if (dateTimeRange.end.diff(dateTimeRange.start, 'second') <= 0) {
      makeToast("시작날짜를 확인해주세요.", 'error')
      return
    }

    setLoading({loading: true})
    const hscm9000Res = await hscm9000Api.findAllByTagCodeAndTimeRange(
      props.tag,
      convertMoment(dateTimeRange.start, "DATE_TIME"),
      convertMoment(dateTimeRange.end, "DATE_TIME"))

    if (hscm9000Res.isError) {
      makeToast(hscm9000Res.desc, "error")
      return
    }

    let hscm9000DataRows = hscm9000Res.data === null ? [] : hscm9000Res.data

    hscm9000DataRows.forEach((item) => {
      item.rtc = formatDate(item.rtc)
    })

    setHscm9000DataRows(wrapIdField(hscm9000DataRows))
    setHscm9000ChartDataset(hscm9000DataRows)
    setLoading({loading: false})
  }

  /**
   * HSCM9000 Chart 데이터 생성
   */
  const setHscm9000ChartDataset = (dataList: Hscm9000Type[]) => {
    const calInsDatasetArray:XyDataType[] = []
    const flowInsDatasetArray:XyDataType[] = []
    const supplyTempInsDatasetArray:XyDataType[] = []
    const returnTempInsDatasetArray:XyDataType[] = []
    const pressInsDatasetArray:XyDataType[] = []

    dataList?.map((value, index) => {
      let rtc = new Date(value.rtc)

      calInsDatasetArray.push({
        x: rtc,
        y: Number(value.calIns)
      })

      flowInsDatasetArray.push({
        x: rtc,
        y: Number(value.flowIns)
      })

      supplyTempInsDatasetArray.push({
        x: rtc,
        y: Number(value.supplyTempIns)
      })

      returnTempInsDatasetArray.push({
        x: rtc,
        y: Number(value.returnTempIns)
      })

      pressInsDatasetArray.push({
        x: rtc,
        y: Number(value.pressIns)
      })
    })

    setCalInsDataset([{
      name: "순간열량",
      data: calInsDatasetArray
    }])
    setFlowInsDataset([{
      name: "순간유량",
      data: flowInsDatasetArray
    }])
    setSupplyTempInsDataset([{
      name: `${supplyTempText}`,
      data: supplyTempInsDatasetArray
    }])
    setReturnTempInsDataset([{
      name: `${returnTempText}`,
      data: returnTempInsDatasetArray
    }])
    setPressInsDataset([{
      name: "압력",
      data: pressInsDatasetArray
    }])
  }


  const reset = () => {
    setDateTimeRagne(getDateTimeRange('1d'))
  }

  return (
    <>
      {/*조회버튼*/}
      <SearchButtonGroup
        searchOnClickHandler={search}
        resetOnClickHandler={reset}
        excelOnClickHandler={() => exportExcel<Hscm9000Type>(hscm9000DataRows, "hscm9000")}
      />

      {/*검색조건*/}
      <Box mb={4}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1} direction="row">
              <DateTimePicker
                label="Start Date Time"
                ampm={false}
                value={dateTimeRange.start}
                onChange={(v) => handleDateTimeOnChange("start", v)}
              />
              <DateTimePicker
                label="End Date Time"
                ampm={false}
                value={dateTimeRange.end}
                onChange={(v) => handleDateTimeOnChange("end", v)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {/*차트*/}
      <Box mb={4}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={6}>
            <LineChart
              title="순간열량"
              xLabel="DateTime"
              yLabel="Gcal"
              series={calInsDataset}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineChart
              title="순간유량"
              xLabel="DateTime"
              yLabel="m3/h"
              series={flowInsDataset}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineChart
              title={`${supplyTempText}`}
              xLabel="DateTime"
              yLabel="C"
              series={supplyTempInsDataset}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LineChart
              title={`${returnTempText}`}
              xLabel="DateTime"
              yLabel="C"
              series={returnTempInsDataset}
            />
          </Grid>
        </Grid>
      </Box>


      {/*그리드*/}
      <DefaultDataGrid
        columns={hscm9000DataColumns}
        rows={hscm9000DataRows}
      />
    </>
  )
}
