import {Card, CardContent, Typography} from "@mui/material";
import React from "react";

type SimpleDisplayCardProps = {
  title: string
  data: string,
  deviceName: string
}

export default function SimpleCard(props:SimpleDisplayCardProps) {
  return (
    <Card sx={{minWidth: 275}}>
      <CardContent>
        <Typography variant="h6" align="center">
          {props.title}
        </Typography>

        <Typography variant="h4" m={4} component="div" align="center">
          {props.data}
        </Typography>

        <Typography variant="body2" color="text.secondary" align="right">
          {props.deviceName}
        </Typography>
      </CardContent>
    </Card>
  )
}