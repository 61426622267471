import React, {useMemo} from 'react';
import {useRecoilValue} from "recoil";
import {loadingState, loginState} from "./state";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./page/login/LoginPage";
import {MainLayout} from "./layout/MainLayout";
import {getRouteList} from "./route/Menu";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {createTheme, ThemeProvider} from "@mui/material";
import DefaultLoading from "./component/loading/DefaultLoading";
import LoginLayout from "./layout/LoginLayout";

function App() {
  const user = useRecoilValue(loginState);
  const loading = useRecoilValue(loadingState);
  const loadingComponent = useMemo(() => <DefaultLoading {...loading} />, [loading]);

  const theme = createTheme({
    // palette: themeConfig?.palette
    palette: {
      mode: "dark",
      primary: {
        main: "#407ad6",
        contrastText: "#FFF",
      },
      background: {
        default: "#1B2635",
        paper: "#233044",
      },
      text: {
        primary: "rgba(255, 255, 255, 0.95)",
        secondary: "rgba(255, 255, 255, 0.5)",
      },
    },
  });

  const renderRoutes = useMemo(() => {
    if(user === null) {
      return (
        <ThemeProvider theme={theme}>
          <LoginLayout>
            <Routes>
              <Route path={"/*"} element={<LoginPage />}/>
            </Routes>
          </LoginLayout>
        </ThemeProvider>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <MainLayout>
          <Routes>
            {
              getRouteList().map((route) => {
                return route
              })
            }
          </Routes>
        </MainLayout>
      </ThemeProvider>
    )
  }, [user, theme])

  return (
    <>
      {loadingComponent}
      <ToastContainer
          position="top-right" // 알람 위치 지정
          autoClose={5000} // 자동 off 시간
          hideProgressBar={true} // 진행시간바 숨김
          closeOnClick // 클릭으로 알람 닫기
          rtl={false} // 알림 좌우 반전
          //pauseOnFocusLoss // 화면을 벗어나면 알람 정지
          draggable // 드래그 가능
          //pauseOnHover // 마우스를 올리면 알람 정지
          theme="dark"
          limit={5}
      />
      {renderRoutes}
    </>
  );
}

export default App;
