import {Card, CardContent, Typography} from "@mui/material";
import {Background, Controls, Edge, Node, Position, ReactFlow, useEdgesState, useNodesState} from "reactflow";
import 'reactflow/dist/style.css';
import React, {useEffect, useMemo} from "react";
import DeviceNode from "./node/DeviceNode";
import CenterNode from "./node/CenterNode";

const initialNodes : Node[] = [
  {
    id: 'lotte.anseong',
    type: 'centerNode',
    position: {x: 1300, y: -300},
    data: {
      id: 'lotte.anseong',
      label: '안성 공장',
      imagePath: '/static/images/lotte-anseong.jpg',
      edges: [
        {
          type: "source",
          position: Position.Bottom
        }
      ]
    },
  },
  {
    id: 'lotte.anseong.hscm9000-1',
    type: 'deviceNode',
    position: {x: 1300, y: 0},
    data: {
      id: 'lotte.gunsan.hscm9000',
      label: '적산열량계 폐열회수',
      imagePath: '/static/images/hscm9000.png',
      centerCode: 'LOTTE_ANSEONG',
      deviceCode: 'HSCM9000_1',
      edges: [
        {
          type: "target",
          position: Position.Top
        }
      ]
    }
  },

  {
    id: 'lotte.opo',
    type: 'centerNode',
    position: {x: 700, y: -300},
    data: {
      id: 'lotte.opo',
      label: '오포 공장',
      imagePath: '/static/images/lotte-opo.jpg',
      edges: [
        {
          type: "source",
          position: Position.Bottom
        }
      ]
    },
  },

  {
    id: 'lotte.opo.hscm9000-1',
    type: 'deviceNode',
    position: {x: 400, y: 0},
    data: {
      id: 'lotte.gunsan.hscm9000',
      label: '적산열량계 보일러1',
      imagePath: '/static/images/hscm9000.png',
      centerCode: 'LOTTE_OPO',
      deviceCode: 'HSCM9000_1',
      edges: [
        {
          type: "target",
          position: Position.Top
        }
      ]
    }
  },
  {
    id: 'lotte.opo.hscm9000-2',
    type: 'deviceNode',
    position: {x: 600, y: 0},
    data: {
      id: 'lotte.gunsan.hscm9000',
      label: '적산열량계 보일러2',
      imagePath: '/static/images/hscm9000.png',
      centerCode: 'LOTTE_OPO',
      deviceCode: 'HSCM9000_2',
      edges: [
        {
          type: "target",
          position: Position.Top
        }
      ]
    }
  },
  {
    id: 'lotte.opo.hscm9000-3',
    type: 'deviceNode',
    position: {x: 800, y: 0},
    data: {
      id: 'lotte.gunsan.hscm9000',
      label: '적산열량계 보일러3',
      imagePath: '/static/images/hscm9000.png',
      centerCode: 'LOTTE_OPO',
      deviceCode: 'HSCM9000_3',
      edges: [
        {
          type: "target",
          position: Position.Top
        }
      ]
    }
  },
  {
    id: 'lotte.opo.hscm9000-4',
    type: 'deviceNode',
    position: {x: 1000, y: 0},
    data: {
      id: 'lotte.gunsan.hscm9000',
      label: '적산열량계 잠열회수',
      imagePath: '/static/images/hscm9000.png',
      centerCode: 'LOTTE_OPO',
      deviceCode: 'HSCM9000_4',
      edges: [
        {
          type: "target",
          position: Position.Top
        }
      ]
    }
  },

  {
    id: 'lotte.gunsan',
    type: 'centerNode',
    position: {x: 0, y: -300},
    data: {
      id: 'lotte.gunsan',
      label: '군산 공장',
      imagePath: '/static/images/lotte-gunsan.jpg',
      edges: [
        {
          type: "source",
          position: Position.Bottom
        }
      ]
    },
  },
  {
    id: 'lotte.gunsan.hscm9000',
    type: 'deviceNode',
    position: {x: -100, y: 0},
    data: {
      id: 'lotte.gunsan.hscm9000',
      label: '적산열량계',
      imagePath: '/static/images/hscm9000.png',
      centerCode: 'LOTTE_GUNSAN',
      deviceCode: 'HSCM9000_1',
      edges: [
        {
          type: "target",
          position: Position.Top
        }
      ]
    }
  },
  {
    id: 'lotte.gunsan.kdx300',
    type: 'deviceNode',
    position: {x: 100, y: 0},
    data: {
      id: 'lotte.gunsan.kdx300',
      label: '전력량계',
      imagePath: '/static/images/kdx300.png',
      centerCode: 'LOTTE_GUNSAN',
      deviceCode: 'KDX300_1',
      edges: [
        {
          type: "target",
          position: Position.Top
        }
      ]
    }
  },
]

const initialEdges = [
  {
    id: 'anseong-hscm9000-1',
    source: 'lotte.anseong',
    target: 'lotte.anseong.hscm9000-1',
    sourceHandle: 'lotte.anseong-bottom',
    animated: true
  },
  {
    id: 'center-hscm9000-1',
    source: 'lotte.opo',
    target: 'lotte.opo.hscm9000-1',
    sourceHandle: 'lotte.opo-bottom',
    animated: true
  },
  {
    id: 'center-hscm9000-2',
    source: 'lotte.opo',
    target: 'lotte.opo.hscm9000-2',
    sourceHandle: 'lotte.opo-bottom',
    animated: true
  },
  {
    id: 'center-hscm9000-3',
    source: 'lotte.opo',
    target: 'lotte.opo.hscm9000-3',
    sourceHandle: 'lotte.opo-bottom',
    animated: true
  },
  {
    id: 'center-hscm9000-4',
    source: 'lotte.opo',
    target: 'lotte.opo.hscm9000-4',
    sourceHandle: 'lotte.opo-bottom',
    animated: true
  },
  {
    id: 'center-hscm9000',
    source: 'lotte.gunsan',
    target: 'lotte.gunsan.hscm9000',
    sourceHandle: 'lotte.gunsan-bottom',
    animated: true
  },
  {
    id: 'center-kdx300',
    source: 'lotte.gunsan',
    target: 'lotte.gunsan.kdx300',
    sourceHandle: 'lotte.gunsan-bottom',
    animated: true
  }
]

export default function FlowBoard() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const nodeTypes = useMemo(() => ({
    deviceNode: DeviceNode,
    centerNode: CenterNode
  }), []);

  useEffect(() => {
    setNodes(initialNodes)
    setEdges(initialEdges)
  }, [])

  return (
    <Card>
      <CardContent sx={{height: 450}}>
        <ReactFlow
          nodes={nodes}
          onNodesChange={onNodesChange}
          edges={edges}
          nodeTypes={nodeTypes}
          style={{ background: '#1B2635' }}
          fitView={true}
        >
          <Controls/>
          <Background/>
        </ReactFlow>
      </CardContent>
    </Card>

  );
}