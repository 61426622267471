import React, {ReactElement, useEffect, useState} from "react";
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import {
  AppBar, Avatar,
  CardHeader,
  Collapse,
  CSSObject,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper as MuiPaper,
  Stack,
  styled,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FolderIcon from '@mui/icons-material/Folder';
import {MenuList, menuTitleFinder, MenuType, SubMenuType} from "../route/Menu";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {UserStorage} from "../storage/UserStorage";
import {useSetRecoilState} from "recoil";
import {loginState} from "../state";
import LoginAPI from "../api/login/LoginApi";
import {LogOut} from "react-feather";

const drawerWidth = 240;

const MainContent = styled(MuiPaper)`
  flex: 1;
  flex-direction: column;
  padding: 1%;
  min-height: 100%;
  background: ${(props) => props.theme.palette.background.default};
  border-radius: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  border: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  //overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //overflowX: 'hidden',
  border: 0,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type layoutProps = {
  children: ReactElement
}

export function MainLayout({children}: layoutProps) {
  const loginApi = LoginAPI();
  const setUser = useSetRecoilState(loginState)

  const [drawerOpen, setDrawerOpen] = useState(true)
  const [selectedMenu, setSelectedMenu] = useState<string>("")
  const [appBarTitle, setAppBarTitle] = useState<string>(menuTitleFinder('/'))

  useEffect(() => {
    const refreshUserIntervalId = setInterval(refreshUser, 1000 * 30)
    refreshUser()

    return () => {
      clearInterval(refreshUserIntervalId)
    }
  }, [])

  const refreshUser = async () => {
    let res = await loginApi.userRefresh()
    if(res.isError) {
      logoutHandler()
      return
    }

    let user = res.data
    setUser(user!)
    UserStorage.setUser(user!)
  }

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(1, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    background: theme.palette.background.default,
    // zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - 65px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const menuClickHandler = (menu: MenuType) => {
    if(menu.children === null) {
      setSelectedMenu(menu.id)
      setAppBarTitle(menuTitleFinder(menu.path))
      navigate(menu.path)
    }
    else {
      selectedMenu === menu.id ? setSelectedMenu("") : setSelectedMenu(menu.id)
    }
  }

  const subMenuClickHandler = (subMenu: SubMenuType) => {
    setAppBarTitle(menuTitleFinder(subMenu.path))
    navigate(subMenu.path)
  }

  const logoutHandler = () => {
    UserStorage.remove()
    setUser(null)
  }

  const navigate = useNavigate();

  return (
    <Box sx={{display: 'flex', minHeight: '100vh'}}>
      <AppBar position="fixed" open={drawerOpen} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            size="large"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{marginRight: 2}}
          >
            {drawerOpen ? <ChevronLeftIcon/> : <MenuIcon />}
          </IconButton>

          <Typography variant="subtitle1" noWrap component="div" sx={{ flexGrow: 1 }}>
            {appBarTitle}
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="로그아웃">
              <IconButton
                size="large"
                color="inherit"
                onClick={logoutHandler}
                edge="start"
              >
                <LogOut
                  size={20}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={drawerOpen}>
        <Stack spacing={1} direction="row">
          <DrawerHeader>
            <List>
              <ListItem
                disablePadding
                sx={{display: 'block'}}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Avatar
                      sx={{width:32, height:32}}
                      alt="sep"
                      src="/static/images/sep-logo.png"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{variant: 'body2'}}
                    primary={"롯데 계측기 모니터링"}
                    sx={{display: drawerOpen ? "block" : "none"}}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </DrawerHeader>
        </Stack>
        <Divider/>
        {/*메뉴*/}
        {MenuList.map((menuGroup) => {
          return (
            <Box key={menuGroup.id}>
              <List
                aria-labelledby={`${menuGroup.id}-subheader`}
              >
                <ListSubheader component="div" id={`${menuGroup.id}-subheader`}
                               sx={{display: drawerOpen ? "block" : "none"}}>
                  {menuGroup.title}
                </ListSubheader>

                {menuGroup.children?.map((menu) => {
                  let isMenuOpen = menu.id === selectedMenu

                  return (
                    <Tooltip
                      disableFocusListener
                      key={menu.id}
                      title={drawerOpen ? null : menu.title}
                      arrow={true}
                      placement="right-start"
                    >
                      <ListItem
                        disablePadding
                        sx={{display: 'block'}}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: drawerOpen ? 'initial' : 'center',
                            px: 2.5,
                          }}
                          onClick={() => menuClickHandler(menu)}
                          selected={selectedMenu === menu.id}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: drawerOpen ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            {menu.icon == null ? <FolderIcon/> : menu.icon}
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{variant: 'body2'}}
                            primary={menu.title}
                            sx={{display: drawerOpen ? "block" : "none"}}
                          />
                          {menu.children != null ?
                            <Box sx={{display: drawerOpen ? "block" : "none"}}>
                              {isMenuOpen ? <ExpandLess/> : <ExpandMore/>}
                            </Box> : <></>}
                        </ListItemButton>
                        {drawerOpen && menu.children && (
                          <Collapse in={isMenuOpen} timeout="auto" unmountOnExit>
                            {menu.children?.map((subMenu) => (
                              <List key={subMenu.id} component="div" disablePadding>
                                <ListItemButton
                                  sx={{pl: 4}}
                                  onClick={() => subMenuClickHandler(subMenu)}
                                  selected={selectedMenu === subMenu.id}
                                >
                                  <ListItemIcon>
                                    {subMenu.icon == null ? <FolderIcon/> : subMenu.icon}
                                  </ListItemIcon>
                                  <ListItemText
                                    primaryTypographyProps={{variant: 'body2'}}
                                    primary={subMenu.title}
                                  />
                                </ListItemButton>
                              </List>
                            ))}
                          </Collapse>
                        )}
                      </ListItem>
                    </Tooltip>
                  )
                })}
              </List>
              <Divider/>
            </Box>
          )
        })}
      </Drawer>

      <MainContent>
        <DrawerHeader />
        {children}
      </MainContent>
    </Box>
  );
}
