import {Badge, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {Handle, Position} from "reactflow";
import React, {useEffect, useState} from "react";
import CommonDeviceApi from "../../../api/device/CommonDeviceApi";
import {makeToast} from "../../../common/CommonUtil";

type DeviceNodeProps = {
  data: {
    id: string,
    label?: string,
    imagePath: string,
    centerCode: string,
    deviceCode: string,
    edges: [{
      type: "source" | "target"
      position: Position
    }] // top, right, bottom, left
  }
}

export default function DeviceNode({ data } : DeviceNodeProps) {
  const [connect, setConnect] = useState<boolean>(false)
  const commonDeviceApi = CommonDeviceApi();

  useEffect(() => {
    const heartBeatIntervalId = setInterval(search, 1000 * 5)

    search()
    return () => {
      clearInterval(heartBeatIntervalId)
    }
  }, [])

  const search = async () => {
    const heartBeatRes = await commonDeviceApi.findHeartBeat(data.centerCode, data.deviceCode)
    if (heartBeatRes.isError) {
      return
    }

    let heartBeat = heartBeatRes.data === null ? false : heartBeatRes.data
    setConnect(heartBeat)
  }

  return (
    <>
      {
        data.edges && data.edges.map((value, index) => {
          if(value) {
            return(
              <Handle
                key={`edge-device-${index}`}
                id={`${data.id}-${value.position}`}
                type={value.type}
                position={value.position}
              />
            )
          }
        })
      }
      <Badge color={connect ? "success" : "error"} badgeContent=" ">
        <Card sx={{ width: 150 }}>
          <CardMedia
            sx={{ height: 140 }}
            image={data.imagePath}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary" align="center">
              {data.label}
            </Typography>
          </CardContent>
        </Card>
      </Badge>
    </>
  )
}