import getAxiosTemplate from "../AxiosTemplate";
import {CommonResponseType, Hscm9000Type, TimeRangeType} from "../../@types";
import {checkApiError} from "../../common/CommonUtil";
import {UserStorage} from "../../storage/UserStorage";

export default function Hscm9000Api() {
  const BASE_URL = "/api/device/hscm9000"

  // const user = useRecoilValue(loginState);
  // const request = getAxiosTemplate(user?.token);
  const user = UserStorage.getUser()
  const request = getAxiosTemplate(user?.token);

  return {
    findAllByTagCodeAndTimeRangeCode: async (tagCode: string, timeRangeCode: TimeRangeType): Promise<CommonResponseType<Hscm9000Type[]>> => {
      const res = await request
        .get<CommonResponseType<Hscm9000Type[]>>(`${BASE_URL}/${tagCode}/${timeRangeCode}`)
        .catch(e => checkApiError<Hscm9000Type[]>(e));

      if (!res) {
        throw Error("HSCM9000 데이터 조회 실패")
      }

      return res.data
    },

    findAllByTagCodeAndTimeRange: async (tagCode: string, startDateTime: string, endDateTime: string) : Promise<CommonResponseType<Hscm9000Type[]>> => {
      const res = await request
        .get<CommonResponseType<Hscm9000Type[]>>(`${BASE_URL}/${tagCode}/${startDateTime}/${endDateTime}`)
        .catch(e => checkApiError<Hscm9000Type[]>(e))

      if(!res) {
        throw Error("HSCM9000 데이터 조회 실패")
      }

      return res.data
    },

    findAllSaveDataByTagCodeAndTimeRange: async (tagCode: string, startDateTime: string, endDateTime: string) : Promise<CommonResponseType<Hscm9000Type[]>> => {
      const res = await request
        .get<CommonResponseType<Hscm9000Type[]>>(`${BASE_URL}/save/${tagCode}/${startDateTime}/${endDateTime}`)
        .catch(e => checkApiError<Hscm9000Type[]>(e))

      if(!res) {
        throw Error("HSCM9000 저장 데이터 조회 실패")
      }

      return res.data
    },
  }
}