import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import {Handle, Position} from "reactflow";
import React from "react";

type CenterNodeProps = {
  data: {
    id: string,
    label?: string,
    imagePath: string,
    edges: [{
      type: "source" | "target"
      position: Position
    }] // top,  // top, right, bottom, left
  }
}

export default function CenterNode({ data } : CenterNodeProps) {
  return (
    <>
      {
        data.edges && data.edges.map((value, index) => {
          if(value) {
            return(
              <Handle
                key={`edge-device-${index}`}
                id={`${data.id}-${value.position}`}
                type={value.type}
                position={value.position}
              />
            )
          }
        })
      }
      <Card sx={{ width: 150 }}>
        <CardMedia
          sx={{ height: 140 }}
          image={data.imagePath}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary" align="center">
            {data.label}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}