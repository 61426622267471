import {Button, Grid, Stack} from "@mui/material";
import {InsertDriveFile, Replay, Search} from "@mui/icons-material";
import React from "react";

type SearchButtonGroupProps = {
  searchOnClickHandler?: () => void
  resetOnClickHandler?: () => void,
  excelOnClickHandler?: () => void,
  extraButtons?: React.JSX.Element[] | null,
}

export default function SearchButtonGroup({
  searchOnClickHandler,
  resetOnClickHandler,
  excelOnClickHandler,
  extraButtons
}: SearchButtonGroupProps) {
  return (
    <Grid
      container
      justifyContent="flex-end"
      spacing={2}
      mb={4}
    >
      <Stack spacing={1} direction="row">
        {
          searchOnClickHandler &&
            <Button
                title="조회"
                startIcon={<Search/>}
                variant="contained"
                onClick={searchOnClickHandler}
            >
                조회
            </Button>
        }

        {
          resetOnClickHandler &&
            <Button
                title="초기화"
                startIcon={<Replay/>}
                variant="contained"
                color="error"
                onClick={resetOnClickHandler}>
                초기화
            </Button>
        }

        {
          excelOnClickHandler &&
            <Button
              title="엑셀"
              startIcon={<InsertDriveFile/>}
              variant="contained"
              color="success"
              onClick={excelOnClickHandler}>
              엑셀
            </Button>
        }

        {extraButtons && extraButtons.map((element, idx) => {
          return element
        })}
      </Stack>
    </Grid>
  )
}