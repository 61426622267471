import AlarmHistoryApi from "../../api/history/AlarmHistoryApi";
import React, {useEffect, useState} from "react";
import {formatDate, makeToast, wrapIdField} from "../../common/CommonUtil";
import {AlarmHistoryType, WrapIdType} from "../../@types";
import SearchButtonGroup from "../../component/board/SearchButtonGroup";
import {GridColDef} from "@mui/x-data-grid";
import DefaultDataGrid from "../../component/board/DefaultDataGrid";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../state";

export default function AlarmHistory() {
  const setLoading = useSetRecoilState(loadingState);
  const alarmHistoryApi = AlarmHistoryApi();
  const [alarmHistoryRows, setAlarmHistoryRows] = useState<WrapIdType<AlarmHistoryType>[]>([])

  useEffect(() => {
    search()
  }, [])

  const search = async () => {
    setLoading({loading: true})
    const res = await alarmHistoryApi.findAll()
    if (res.isError) {
      makeToast(res.desc, "error")
      return
    }

    let resData = res.data === null ? [] : res.data
    resData.forEach((item) => {
      item.issueDt = formatDate(item.issueDt)
    })

    setAlarmHistoryRows(wrapIdField(resData))
    setLoading({loading: false})
  }

  const alarmHistoryColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align: 'center',
      width: 80
    },
    {
      field: 'centerCode',
      headerName: '센터 코드',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'deviceCode',
      headerName: '장비 코드',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'alarmCode',
      headerName: '알람 코드',
      headerAlign: 'center',
      align: 'center',
      width: 250
    },
    {
      field: 'description',
      headerName: '내용',
      headerAlign: 'center',
      align: 'center',
      width: 300
    },
    {
      field: 'issueDt',
      headerName: '발생시간',
      headerAlign: 'center',
      align: 'center',
      width: 250
    },
  ]

  return (
    <>
      {/*조회버튼*/}
      <SearchButtonGroup
        searchOnClickHandler={search}
        excelOnClickHandler={() => {}}
      />

      {/*그리드*/}
      <DefaultDataGrid
        columns={alarmHistoryColumns}
        rows={alarmHistoryRows}
      />
    </>
  )
}