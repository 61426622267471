import getAxiosTemplate from "../AxiosTemplate";
import {CommonResponseType} from "../../@types";
import {checkApiError} from "../../common/CommonUtil";
import {UserStorage} from "../../storage/UserStorage";

type JobType = "HSCM9000_METER_READING"

export default function JobApi() {
  const BASE_URL = "/api/job"

  // const user = useRecoilValue(loginState);
  // const request = getAxiosTemplate(user?.token);
  const user = UserStorage.getUser()
  const request = getAxiosTemplate(user?.token);

  return {
    register: async (jobType: string, data: any) : Promise<CommonResponseType<string>> => {
      const param = {
        "jobType": jobType,
        "data": data
      }

      const res = await request
        .post<CommonResponseType<string>>(`${BASE_URL}/register`, param)
        .catch(e => checkApiError<string>(e));

      if(!res) {
        throw Error("작업 요청 실패")
      }

      return res.data
    }
  }

}