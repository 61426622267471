import {Box, CircularProgress, Modal} from "@mui/material";

type DefaultLoadingType = {
  loading: boolean
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99999,
};

export default function DefaultLoading(props : DefaultLoadingType) {
  return (
    <Modal
      open={props.loading}
    >
      <Box sx={{...modalStyle}}>
        <CircularProgress color="primary" />
      </Box>
    </Modal>
  )
}