import {Paper as MuiPaper, styled} from "@mui/material";
import {ReactElement} from "react";

const MainContent = styled(MuiPaper)`
  flex: 1;
  flex-direction: column;
  padding: 1%;
  min-height: 100vh;
  background: ${(props) => props.theme.palette.background.default};
  border-radius: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type layoutProps = {
  children: ReactElement
}
export default function LoginLayout({children}: layoutProps) {
  return (
    <MainContent>
      {children}
    </MainContent>
  )
}