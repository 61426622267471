import Hscm9000Api from "../../api/device/Hscm9000Api";
import React, {useEffect, useState} from "react";
import {Hscm9000Type, Kdx300Type, SearchDateTimeRangeType, WrapIdType} from "../../@types";
import {
  convertMoment,
  exportExcel,
  formatDate,
  getDateTimeRange,
  makeToast,
  wrapIdField
} from "../../common/CommonUtil";
import {Alert, Box, Button, Grid, Stack} from "@mui/material";
import moment from "moment/moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {Activity} from "react-feather";
import SearchButtonGroup from "../../component/board/SearchButtonGroup";
import JobApi from "../../api/job/JobApi";
import {GridColDef} from "@mui/x-data-grid";
import DefaultDataGrid from "../../component/board/DefaultDataGrid";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../state";

type Hscm9000ActionPageProps = {
  tag: string,
  job: string,
}

export default function Hscm9000ActionPage(props: Hscm9000ActionPageProps) {
  const setLoading = useSetRecoilState(loadingState);
  const hscm9000Api = Hscm9000Api();
  const jobApi = JobApi();

  const [dateTimeRange, setDateTimeRagne]
    = useState<SearchDateTimeRangeType>(getDateTimeRange('30d'))

  const [hscm9000SaveDataRows, setHscm9000SaveDataRows] = useState<WrapIdType<Hscm9000Type>[]>([])

  useEffect(() => {
    search()
  }, [])

  const handleDateTimeOnChange = (target: "start" | "end", value: moment.Moment | null) => {
    if (value === null) {
      return
    }

    if (target === "start") {
      setDateTimeRagne({...dateTimeRange, start: value})
    } else {
      setDateTimeRagne({...dateTimeRange, end: value})
    }
  }

  const search = async () => {
    // Validation
    if (dateTimeRange.end.diff(dateTimeRange.start, 'day') < 0) {
      makeToast("시작날짜를 확인해주세요.", 'error')
      return
    }

    setLoading({loading: true})
    const hscm9000SaveRes = await hscm9000Api.findAllSaveDataByTagCodeAndTimeRange(
      props.tag,
      convertMoment(dateTimeRange.start, "DATE"),
      convertMoment(dateTimeRange.end, "DATE"))

    if (hscm9000SaveRes.isError) {
      makeToast(hscm9000SaveRes.desc, "error")
      return
    }

    let hscm9000SaveDataRows = hscm9000SaveRes.data === null ? [] : hscm9000SaveRes.data
    hscm9000SaveDataRows.forEach((item) => {
      item.rtc = formatDate(item.rtc)
    })

    setHscm9000SaveDataRows(wrapIdField(hscm9000SaveDataRows))
    setLoading({loading: false})
  }

  const reset = () => {
    setDateTimeRagne(getDateTimeRange('30d'))
  }

  const saveDataRequest = async () => {
    if (dateTimeRange.end.diff(dateTimeRange.start, 'day') < 0) {
      makeToast("시작일을 확인해주세요.", 'error')
      return
    }

    if (dateTimeRange.end.diff(dateTimeRange.start, 'day') >= 31) {
      makeToast(" 31일 이상은 서버에 부담이 갑니다. 일수를 확인해주세요.", 'error')
      return
    }

    setLoading({loading: true})
    const startDate = dateTimeRange.start.format("YYYY-MM-DD")
    const endDate = dateTimeRange.end.format("YYYY-MM-DD")
    const jobRegisterRes = await jobApi.register(props.job, {
      tag: props.tag,
      startDate: startDate,
      endDate: endDate
    })

    if (jobRegisterRes.isError) {
      makeToast(jobRegisterRes.desc, "error")
      return
    }
    setLoading({loading: false})

    makeToast("요청 완료", "success")
  }

  const hscm9000SaveDataColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align: 'center',
      width: 80
    },
    {
      field: 'rtc',
      headerName: '시간',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'calIns',
      headerName: '순간열량 Gcal',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'calSum',
      headerName: '적산열량 Gcal',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'flowIns',
      headerName: '순간유량 m3/h',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'flowSum',
      headerName: '적산유량 m3/h',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'supplyTempIns',
      headerName: '공급온도 C',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'returnTempIns',
      headerName: '회수온도 C',
      headerAlign: 'center',
      align: 'center',
      width: 200
    }
  ]

  return (
    <>
      {/*조회버튼*/}
      <SearchButtonGroup
        searchOnClickHandler={search}
        resetOnClickHandler={reset}
        excelOnClickHandler={() => exportExcel<Hscm9000Type>(hscm9000SaveDataRows, "hscm9000_save")}
        extraButtons={[
          <Button
            key={"1"}
            title="저장검침 요청"
            startIcon={<Activity/>}
            variant="contained"
            color="warning"
            onClick={saveDataRequest}
          >
            저장검침 요청
          </Button>
        ]}
      />

      {/*저장검침 요청 && 검색조건 */}
      <Box mb={4}>
        <Alert severity="error" sx={{marginBottom: 2}}>
          해당 요청은, 즉시 검침 데이터보다 우선순위가 높습니다. 요청 범위에 따라 즉시 검침 요청이 밀리게 됩니다.
        </Alert>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1} direction="row">
              <DesktopDatePicker
                label="시작일"
                views={['year', 'month', 'day']}
                value={dateTimeRange.start}
                format={"YYYY-MM-DD"}
                onChange={(v) => handleDateTimeOnChange("start", v)}
              />
              <DesktopDatePicker
                label="종료일"
                views={['year', 'month', 'day']}
                value={dateTimeRange.end}
                format={"YYYY-MM-DD"}
                onChange={(v) => handleDateTimeOnChange("end", v)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {/*그리드*/}
      <DefaultDataGrid
        columns={hscm9000SaveDataColumns}
        rows={hscm9000SaveDataRows}
      />
    </>
  )
}