import {UserStorage} from "../../storage/UserStorage";
import getAxiosTemplate from "../AxiosTemplate";
import {CommonResponseType, Kdx300Type} from "../../@types";
import {checkApiError} from "../../common/CommonUtil";

export default function CommonDeviceApi() {
  const BASE_URL = "/api/device"

  const user = UserStorage.getUser()
  const request = getAxiosTemplate(user?.token);

  return {
    findHeartBeat: async (centerCode: String, deviceCode: String): Promise<CommonResponseType<boolean>> => {
      const res = await request.get<CommonResponseType<boolean>>(`${BASE_URL}/heart-beat/${centerCode}/${deviceCode}`)
        .catch(e => checkApiError<boolean>(e))
      if(!res) {
        throw Error("하트비트 조회 실패")
      }

      return res.data
    }
  }
}