import {UserType} from "../@types";

export const UserStorage = {
  setUser: (user: UserType) => {
    localStorage.setItem("user", JSON.stringify(user))
  },

  getUser: (): null | UserType => {
    const userJsonString = localStorage.getItem("user")
    if(userJsonString == null) {
      return null
    }

    return JSON.parse(userJsonString)
  },

  remove: () => {
    localStorage.removeItem("user")
  }
}