import {Avatar, Box, Button, Container, CssBaseline, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, {useEffect, useState} from "react";
import LoginAPI from "../../api/login/LoginApi";
import {useSetRecoilState} from "recoil";
import {loginState} from "../../state";
import {makeToast} from "../../common/CommonUtil";
import {UserStorage} from "../../storage/UserStorage";

export default function LoginPage() {
  const loginApi = LoginAPI();

  const [userId, setUserId] = useState("")
  const [userPw, setUserPw] = useState("")

  const setUser = useSetRecoilState(loginState);

  const login = async () => {
      let res = await loginApi.userLogin(userId, userPw)
      if(res.isError) {
          makeToast(res.desc, "error")
          return
      }

      let user = res.data
      setUser(user!)
      UserStorage.setUser(user!)
  }

  useEffect(() => {
    let user = UserStorage.getUser()
    if(user != null) {
      setUser(user)
    }
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate sx={{mt: 1}}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="userId"
            label="User Id"
            name="userId"
            autoFocus
            onChange={(e) => setUserId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setUserPw(e.target.value)}
          />
          <Button
            onClick={login}
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  )
}