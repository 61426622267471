import axios from 'axios';

const baseURL = process.env.REACT_APP_API_SERVER_URL;

export default function getAxiosTemplate(token?: String) {
  if (token === undefined || token === "") {
    return axios.create({
      'baseURL': baseURL
    });
  } else {
    return axios.create({
      'baseURL': baseURL,
      'headers': {
        "Authorization": `${token}`
      }
    })
  }
}