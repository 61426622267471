import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import React from "react";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";
import NoDataRow from "./NoDataRow";

type DefaultDataGridProps = {
  columns: GridColDef[],
  rows: GridRowsProp,
}

export default function DefaultDataGrid(props :DefaultDataGridProps) {
  return (
    <div style={{height: "550px", width: "100%" }}>
      <DataGrid
        columns={props.columns}
        rows={props.rows}
        sx={{
          boxShadow: 0,
          border: 0,
        }}
        density={'compact'}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } }
        }}
        pageSizeOptions={[25, 50, 100]}

        slots={{
          toolbar: () => {
            return (
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector/>
              </GridToolbarContainer>
            )
          },
          noRowsOverlay: NoDataRow
        }}
      />
    </div>
  )
}