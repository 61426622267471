import {AxiosError, AxiosResponse} from "axios";
import {CommonResponseType, SearchDateTimeRangeType, TimeRangeType, WrapIdType} from "../@types";
import {toast} from "react-toastify";
import {TypeOptions} from "react-toastify/dist/types";
import moment from "moment/moment";

export const checkApiError = <T>(e: AxiosError): AxiosResponse<CommonResponseType<T>> | undefined => {
  if (!e.response) {
    return undefined
  }

  if (e.response.status === 403) {
    return {
      ...e.response,
      data: {
        isError: true,
        errorCode: "9999",
        desc: "권한이 없습니다.",
        data: null
      }
    }
  }

  if (e.response.data) {
    const data = e.response.data as CommonResponseType<any>

    return {
      ...e.response,
      data: data
    }
  }
}

export const makeToast = (message: string, type: TypeOptions) => {
  toast(message, {type: type})
}

export const getDateTimeRange = (timeRange: TimeRangeType): SearchDateTimeRangeType => {
  let startDateTime: moment.Moment
  switch (timeRange) {
    case "1d":
      startDateTime = moment().subtract(1, 'day')
      break
    case "7d":
      startDateTime = moment().subtract(7, 'day')
      break
    case "30d":
    case "1m":
      startDateTime = moment().subtract(1, 'month')
      break
    case "1y":
      startDateTime = moment().subtract(1, 'year')
      break;
    default:
      startDateTime = moment().subtract(1, 'day')
      break;
  }

  const endDateTime = moment()

  return {
    start: startDateTime,
    end: endDateTime
  }
}

export const convertMoment = (moment: moment.Moment, format: "DATE" | "DATE_TIME"): string => {
  switch (format) {
    case "DATE":
      return moment.format('YYYY-MM-DD')
    case "DATE_TIME":
      return moment.format('YYYY-MM-DD HH:mm:ss')
  }
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * 그리드 No Wrapper
 */
export const wrapIdField = <T>(list: T[]): WrapIdType<T>[] => {
  const resultList: WrapIdType<T>[] = []

  list.forEach((item, idx) => {
    resultList.push({...item, id: (idx + 1)})
  })

  return resultList
}

/**
 * excel
 */
export const exportExcel = <T>(dataList: T[], fileName:string) => {
  const postfix = convertMoment(moment(), "DATE")
  const xlsx = require("xlsx")
  const ws = xlsx.utils.json_to_sheet(dataList);
  const wb = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, `${fileName}_${postfix}.xlsx`);
}