import getAxiosTemplate from "../AxiosTemplate";
import {AlarmHistoryType, CommonResponseType} from "../../@types";
import {checkApiError} from "../../common/CommonUtil";
import {UserStorage} from "../../storage/UserStorage";

export default function AlarmHistoryApi() {
  const BASE_URL = "/api/history/alarm"

  // const user = useRecoilValue(loginState);
  // const request = getAxiosTemplate(user?.token);
  const user = UserStorage.getUser()
  const request = getAxiosTemplate(user?.token);

  return {
    findAll: async () => {
      const res = await request
        .get<CommonResponseType<AlarmHistoryType[]>>(`${BASE_URL}`)
        .catch(e => checkApiError<AlarmHistoryType[]>(e));

      if (!res) {
        throw Error("알람 히스토리 데이터 조회 실패")
      }

      return res.data
    }
  }
}