import {
  Airplay, Cpu, Activity, AlertTriangle
} from "react-feather";

import React from "react";
import MainPage from "../page/MainPage";
import {Route} from "react-router-dom";
import Hscm9000Page from "../page/device/Hscm9000Page";
import Kdx300Page from "../page/device/Kdx300Page";
import Hscm9000ActionPage from "../page/action/Hscm9000ActionPage";
import AlarmHistory from "../page/history/AlarmHistory";

type MenuGroupType = {
  id: string
  title: string
  children: MenuType[] | null
}

export type MenuType = {
  id: string
  title: string,
  path: string,
  icon: React.JSX.Element | null,
  page: React.JSX.Element | null,
  children: SubMenuType[] | null
}

export type SubMenuType = {
  id: string
  title: string,
  path: string,
  icon: React.JSX.Element | null,
  page: React.JSX.Element | null,
}

export const menuTitleFinder = (path: string) => {
  let title = ""

  for(let menuGroup of MenuList) {
    let menuGroupTitle = menuGroup.title
    let menuGroupChildren = menuGroup.children
    if(menuGroupChildren != null) {
      for(let menu of menuGroupChildren) {
        let menuTitle = menu.title

        if(menu.path === path) {
          if(menuGroupTitle == "") {
            title = `${menuTitle}`
          }
          else {
            title = `${menuGroupTitle} > ${menuTitle}`
          }

          break
        }

        let menuChildren = menu.children
        if(menuChildren != null) {
          for(let subMenu of menuChildren) {
            let subMenuTitle = subMenu.title

            if(subMenu.path === path) {
              if(menuGroupTitle == "") {
                title = `${menuTitle} > ${subMenuTitle}`
              }
              else {
                title = `${menuGroupTitle} > ${menuTitle} > ${subMenuTitle}`
              }
              break
            }
          }
        }
      }
    }
  }

  return title
}

export const MenuList : MenuGroupType[] = [
  {
    id: 'main',
    title: "",
    children: [
      {
        id:"lotte-gunsan-dashboard",
        title: "대시보드",
        path: "/",
        icon: <Airplay />,
        page: <MainPage />,
        children: null
      },
    ]
  },
  {
    id: "lotte-gunsan",
    title: "군산",
    children: [

      {
        id: "lotte-gunsan-device",
        title: "디바이스",
        path: "/lotte/gunsan",
        icon: <Cpu />,
        page: null,
        children: [
          {
            id:  "lotte-gunsan-deivce-hscm9000",
            title: "적산열량계",
            path: "/lotte/gunsan/hscm9000",
            icon: <Cpu />,
            page: <Hscm9000Page tag={"LOTTE_GUNSAN_HSCM9000_1"}/>
          },
          {
            id:  "lotte-gunsan-deivce-kdx300",
            title: "전력량계",
            path: "/lotte/gunsan/kdx300",
            icon: <Cpu />,
            page: <Kdx300Page />
          }
        ]
      },
      {
        id:"lotte-gunsan-action",
        title: "원격검침",
        path: "/lotte/gunsan/action",
        icon: <Activity />,
        page: null,
        children: [
          {
            id:  "lotte-gunsan-deivce-hscm9000-1",
            title: "적산열량계",
            path: "/lotte/gunsan/action/hscm9000-1",
            icon: <Cpu />,
            page: <Hscm9000ActionPage tag={"LOTTE_GUNSAN_HSCM9000_1"} job={"GUNSAN_HSCM_9000_MEATER_READING"}/>,
          },
        ]
      },
      {
        id:"lotte-gunsan-history",
        title: "알람",
        path: "/lotte/gunsan/history/alarm",
        icon: <AlertTriangle />,
        page: <AlarmHistory />,
        children: null
      },
    ]
  },
  {
    id: "lotte-opo",
    title: "오포",
    children: [

      {
        id: "lotte-opo-device",
        title: "디바이스",
        path: "/lotte/opo",
        icon: <Cpu />,
        page: null,
        children: [
          {
            id:  "lotte-opo-deivce-hscm9000-1",
            title: "적산열량계 보일러1",
            path: "/lotte/opo/hscm9000-1",
            icon: <Cpu />,
            page: <Hscm9000Page tag={"LOTTE_OPO_HSCM9000_1"}/>
          },
          {
            id:  "lotte-opo-deivce-hscm9000-2",
            title: "적산열량계 보일러2",
            path: "/lotte/opo/hscm9000-2",
            icon: <Cpu />,
            page: <Hscm9000Page tag={"LOTTE_OPO_HSCM9000_2"}/>
          },
          {
            id:  "lotte-opo-deivce-hscm9000-3",
            title: "적산열량계 보일러3",
            path: "/lotte/opo/hscm9000-3",
            icon: <Cpu />,
            page: <Hscm9000Page tag={"LOTTE_OPO_HSCM9000_3"}/>
          },
          {
            id:  "lotte-opo-deivce-hscm9000-4",
            title: "적산열량계 잠열회수",
            path: "/lotte/opo/hscm9000-4",
            icon: <Cpu />,
            page: <Hscm9000Page tag={"LOTTE_OPO_HSCM9000_4"}/>
          },
        ]
      },
      {
        id:"lotte-opo-action",
        title: "원격검침",
        path: "/lotte/opo/action",
        icon: <Activity />,
        page: null,
        children: [
          {
            id:  "lotte-opo-deivce-hscm9000-1",
            title: "적산열량계 보일러1",
            path: "/lotte/opo/action/hscm9000-1",
            icon: <Cpu />,
            page: <Hscm9000ActionPage tag={"LOTTE_OPO_HSCM9000_1"} job={"OPO_HSCM_9000_1_MEATER_READING"}/>,
          },
          {
            id:  "lotte-opo-deivce-hscm9000-2",
            title: "적산열량계 보일러2",
            path: "/lotte/opo/action/hscm9000-2",
            icon: <Cpu />,
            page: <Hscm9000ActionPage tag={"LOTTE_OPO_HSCM9000_2"} job={"OPO_HSCM_9000_2_MEATER_READING"}/>,
          },
          {
            id:  "lotte-opo-deivce-hscm9000-3",
            title: "적산열량계 보일러3",
            path: "/lotte/opo/action/hscm9000-3",
            icon: <Cpu />,
            page: <Hscm9000ActionPage tag={"LOTTE_OPO_HSCM9000_3"} job={"OPO_HSCM_9000_3_MEATER_READING"}/>,
          },
          {
            id:  "lotte-opo-deivce-hscm9000-4",
            title: "적산열량계 잠열회수",
            path: "/lotte/opo/action/hscm9000-4",
            icon: <Cpu />,
            page: <Hscm9000ActionPage tag={"LOTTE_OPO_HSCM9000_4"} job={"OPO_HSCM_9000_4_MEATER_READING"}/>,
          },
        ]
      },
      {
        id:"lotte-gunsan-history",
        title: "알람",
        path: "/lotte/gunsan/history/alarm",
        icon: <AlertTriangle />,
        page: <AlarmHistory />,
        children: null
      },
    ]
  },
  {
    id: "lotte-anseong",
    title: "안성",
    children: [

      {
        id: "lotte-anseong-device",
        title: "디바이스",
        path: "/lotte/anseong",
        icon: <Cpu />,
        page: null,
        children: [
          {
            id:  "lotte-anseong-deivce-hscm9000",
            title: "적산열량계 폐열회수",
            path: "/lotte/anseong/hscm9000",
            icon: <Cpu />,
            page: <Hscm9000Page tag={"LOTTE_ANSEONG_HSCM9000_1"}/>
          }
        ]
      },
      {
        id:"lotte-anseong-action",
        title: "원격검침",
        path: "/lotte/anseong/action",
        icon: <Activity />,
        page: null,
        children: [
          {
            id:  "lotte-opo-deivce-hscm9000-1",
            title: "적산열량계 폐열회수",
            path: "/lotte/anseong/action/hscm9000-1",
            icon: <Cpu />,
            page: <Hscm9000ActionPage tag={"LOTTE_ANSEONG_HSCM9000_1"} job={"ANSEONG_HSCM_9000_MEATER_READING"}/>,
          },
        ]
      },
      {
        id:"lotte-anseong-history",
        title: "알람",
        path: "/lotte/anseong/history/alarm",
        icon: <AlertTriangle />,
        page: <AlarmHistory />,
        children: null
      },
    ]
  },
]

export const getRouteList = () => {
  let routeList : React.JSX.Element[] = []

  MenuList.map((menuGroup) => (
    menuGroup.children?.map((menu) => {
      if(menu.page != null) {
        routeList.push(<Route key={menu.id} path={menu.path} element={menu.page}/>)
      }
      menu.children?.map((subMenu) => {
        if(subMenu.page != null) {
          routeList.push(<Route key={subMenu.id} path={subMenu.path} element={subMenu.page}/>)
        }
      })
    })
  ))

  return routeList
}