import {Alert, Chip, Divider, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import moment from "moment";
import SimpleCard from "../component/card/SimpleCard";
import {convertMoment, makeToast} from "../common/CommonUtil";
import {Hscm9000Type, Kdx300Type} from "../@types";
import Hscm9000Api from "../api/device/Hscm9000Api";
import Kdx300Api from "../api/device/Kdx300Api";
import FlowBoard from "../component/flow/FlowBoard";
import {Monitor, Share2} from "react-feather";

export default function MainPage(){
  const hscm9000Api = Hscm9000Api();
  const kdx300Api = Kdx300Api();

  const [currentTimeState, setCurrentTimeState] = useState(convertMoment(moment(), "DATE_TIME"))
  const [gunsanHscm9000State1, setGunsanHscm9000State1] = useState<Hscm9000Type>()
  const [gunsanKdx300State, setGunsanKdx300State] = useState<Kdx300Type>()

  const [opoHscm9000State1, setOpoHscm9000State1] = useState<Hscm9000Type>()
  const [opoHscm9000State2, setOpoHscm9000State2] = useState<Hscm9000Type>()
  const [opoHscm9000State3, setOpoHscm9000State3] = useState<Hscm9000Type>()
  const [opoHscm9000State4, setOpoHscm9000State4] = useState<Hscm9000Type>()

  const [anseongHscm9000State1, setAnseongHscm9000State1] = useState<Hscm9000Type>()

  // Onload
  useEffect(() => {
    search()

    const searchIntervalId = setInterval(search, 30000)

    return () => {
      clearInterval(searchIntervalId)
    }
  }, [])

  /**
   * 모니터링 데이터 조회
   */
  const search = async () => {
    searchHscmData("LOTTE_GUNSAN_HSCM9000_1")
    searchHscmData("LOTTE_OPO_HSCM9000_1")
    searchHscmData("LOTTE_OPO_HSCM9000_2")
    searchHscmData("LOTTE_OPO_HSCM9000_3")
    searchHscmData("LOTTE_OPO_HSCM9000_4")
    searchHscmData("LOTTE_ANSEONG_HSCM9000_1")

    // 군산 KDX300 Data 조회
    const kdx300Res = await kdx300Api.findAllTagCodeAndTimeRangeCode("LOTTE_GUNSAN_KDX300_1", "24h")
    if(kdx300Res.isError) {
      return
    }

    let kdx300DataList = kdx300Res.data == null ? [] : kdx300Res.data
    if(kdx300DataList.length > 0) {
      let kdx300Data = kdx300DataList[0]
      setGunsanKdx300State(kdx300Data)
    }

    setCurrentTimeState(moment().format('YYYY-MM-DD HH:mm:ss'))
  }

  const searchHscmData = async (tagCode: string) => {
    const hscm9000Res = await hscm9000Api.findAllByTagCodeAndTimeRangeCode(tagCode, "24h")
    if(hscm9000Res.isError) {
      return
    }

    let hscm9000DataList = hscm9000Res.data === null ? [] : hscm9000Res.data
    if(hscm9000DataList.length > 0) {
      let hscm9000Data = hscm9000DataList[0]

      switch(tagCode) {
        case "LOTTE_GUNSAN_HSCM9000_1":
          setGunsanHscm9000State1(hscm9000Data)
          break
        case "LOTTE_OPO_HSCM9000_1":
          setOpoHscm9000State1(hscm9000Data)
          break
        case "LOTTE_OPO_HSCM9000_2":
          setOpoHscm9000State2(hscm9000Data)
          break
        case "LOTTE_OPO_HSCM9000_3":
          setOpoHscm9000State3(hscm9000Data)
          break
        case "LOTTE_OPO_HSCM9000_4":
          setOpoHscm9000State4(hscm9000Data)
          break
        case "LOTTE_ANSEONG_HSCM9000_1":
          setAnseongHscm9000State1(hscm9000Data)
          break
      }
    }
  }

  return (
    <>
      {/*조회시간*/}
      <Grid container mb={2}>
        <Grid item xs={12} lg={12}>
          <Grid container justifyContent="flex-end">
            <Chip label={currentTimeState} />
          </Grid>
        </Grid>
      </Grid>

      {/*HSCM9000 Data*/}
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Alert icon={<Monitor />} severity="info">
            오포 공장 (보일러 1)
          </Alert>
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간열량 Gcal"
            data={`${opoHscm9000State1?.calSum === undefined ? "0" : opoHscm9000State1?.calIns}`}
            deviceName="오포 적산열량계(보일러1)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산열량 Gcal"
            data={`${opoHscm9000State1?.calSum === undefined ? "0" : opoHscm9000State1?.calSum}`}
            deviceName="오포 적산열량계(보일러1)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간유량 m3/h"
            data={`${opoHscm9000State1?.flowIns === undefined ? "0" : opoHscm9000State1?.flowIns}`}
            deviceName="오포 적산열량계(보일러1)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산유량 m3"
            data={`${opoHscm9000State1?.flowSum === undefined ? "0" : opoHscm9000State1?.flowSum}`}
            deviceName="오포 적산열량계(보일러1)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="공급온도 C"
            data={`${opoHscm9000State1?.supplyTempIns === undefined ? "0" : opoHscm9000State1?.supplyTempIns}`}
            deviceName="오포 적산열량계(보일러1)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="회수온도 C"
            data={`${opoHscm9000State1?.returnTempIns === undefined ? "0" : opoHscm9000State1?.returnTempIns}`}
            deviceName="오포 적산열량계(보일러1)"
          />
        </Grid>
      </Grid>

      <Divider sx={{marginBottom: 2, marginTop: 2}} />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Alert icon={<Monitor />} severity="info">
            오포 공장 (보일러 2)
          </Alert>
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간열량 Gcal"
            data={`${opoHscm9000State2?.calSum === undefined ? "0" : opoHscm9000State2?.calIns}`}
            deviceName="오포 적산열량계(보일러2)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산열량 Gcal"
            data={`${opoHscm9000State2?.calSum === undefined ? "0" : opoHscm9000State2?.calSum}`}
            deviceName="오포 적산열량계(보일러2)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간유량 m3/h"
            data={`${opoHscm9000State2?.flowIns === undefined ? "0" : opoHscm9000State2?.flowIns}`}
            deviceName="오포 적산열량계(보일러2)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산유량 m3"
            data={`${opoHscm9000State2?.flowSum === undefined ? "0" : opoHscm9000State2?.flowSum}`}
            deviceName="오포 적산열량계(보일러2)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="공급온도 C"
            data={`${opoHscm9000State2?.supplyTempIns === undefined ? "0" : opoHscm9000State2?.supplyTempIns}`}
            deviceName="오포 적산열량계(보일러2)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="회수온도 C"
            data={`${opoHscm9000State2?.returnTempIns === undefined ? "0" : opoHscm9000State2?.returnTempIns}`}
            deviceName="오포 적산열량계(보일러2)"
          />
        </Grid>
      </Grid>

      <Divider sx={{marginBottom: 2, marginTop: 2}} />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Alert icon={<Monitor />} severity="info">
            오포 공장 (보일러 3)
          </Alert>
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간열량 Gcal"
            data={`${opoHscm9000State3?.calSum === undefined ? "0" : opoHscm9000State3?.calIns}`}
            deviceName="오포 적산열량계(보일러3)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산열량 Gcal"
            data={`${opoHscm9000State3?.calSum === undefined ? "0" : opoHscm9000State3?.calSum}`}
            deviceName="오포 적산열량계(보일러3)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간유량 m3/h"
            data={`${opoHscm9000State3?.flowIns === undefined ? "0" : opoHscm9000State3?.flowIns}`}
            deviceName="오포 적산열량계(보일러3)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산유량 m3"
            data={`${opoHscm9000State3?.flowSum === undefined ? "0" : opoHscm9000State3?.flowSum}`}
            deviceName="오포 적산열량계(보일러3)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="공급온도 C"
            data={`${opoHscm9000State3?.supplyTempIns === undefined ? "0" : opoHscm9000State3?.supplyTempIns}`}
            deviceName="오포 적산열량계(보일러3)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="회수온도 C"
            data={`${opoHscm9000State3?.returnTempIns === undefined ? "0" : opoHscm9000State3?.returnTempIns}`}
            deviceName="오포 적산열량계(보일러3)"
          />
        </Grid>
      </Grid>

      <Divider sx={{marginBottom: 2, marginTop: 2}} />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Alert icon={<Monitor />} severity="info">
            오포 공장 (잠열회수)
          </Alert>
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간열량 Gcal"
            data={`${opoHscm9000State4?.calSum === undefined ? "0" : opoHscm9000State4?.calIns}`}
            deviceName="오포 적산열량계(잠열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산열량 Gcal"
            data={`${opoHscm9000State4?.calSum === undefined ? "0" : opoHscm9000State4?.calSum}`}
            deviceName="오포 적산열량계(잠열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간유량 m3/h"
            data={`${opoHscm9000State4?.flowIns === undefined ? "0" : opoHscm9000State4?.flowIns}`}
            deviceName="오포 적산열량계(잠열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산유량 m3"
            data={`${opoHscm9000State4?.flowSum === undefined ? "0" : opoHscm9000State4?.flowSum}`}
            deviceName="오포 적산열량계(잠열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="공급온도 C"
            data={`${opoHscm9000State4?.supplyTempIns === undefined ? "0" : opoHscm9000State4?.supplyTempIns}`}
            deviceName="오포 적산열량계(잠열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="회수온도 C"
            data={`${opoHscm9000State4?.returnTempIns === undefined ? "0" : opoHscm9000State4?.returnTempIns}`}
            deviceName="오포 적산열량계(잠열회수)"
          />
        </Grid>
      </Grid>

      <Divider sx={{marginBottom: 2, marginTop: 2}} />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Alert icon={<Monitor />} severity="info">
            안성 공장 (폐열회수)
          </Alert>
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간열량 Gcal"
            data={`${anseongHscm9000State1?.calSum === undefined ? "0" : anseongHscm9000State1?.calIns}`}
            deviceName="안성 적산열량계(폐열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산열량 Gcal"
            data={`${anseongHscm9000State1?.calSum === undefined ? "0" : anseongHscm9000State1?.calSum}`}
            deviceName="안성 적산열량계(폐열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간유량 m3/h"
            data={`${anseongHscm9000State1?.flowIns === undefined ? "0" : anseongHscm9000State1?.flowIns}`}
            deviceName="안성 적산열량계(폐열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산유량 m3"
            data={`${anseongHscm9000State1?.flowSum === undefined ? "0" : anseongHscm9000State1?.flowSum}`}
            deviceName="안성 적산열량계(폐열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="공급온도 C"
            data={`${anseongHscm9000State1?.supplyTempIns === undefined ? "0" : anseongHscm9000State1?.supplyTempIns}`}
            deviceName="안성 적산열량계(폐열회수)"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="회수온도 C"
            data={`${anseongHscm9000State1?.returnTempIns === undefined ? "0" : anseongHscm9000State1?.returnTempIns}`}
            deviceName="안성 적산열량계(폐열회수)"
          />
        </Grid>
      </Grid>

      <Divider sx={{marginBottom: 2, marginTop: 2}} />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Alert icon={<Monitor />} severity="info">
            군산 공장
          </Alert>
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간열량 Gcal"
            data={`${gunsanHscm9000State1?.calSum === undefined ? "0" : gunsanHscm9000State1?.calIns}`}
            deviceName="군산 적산열량계"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산열량 Gcal"
            data={`${gunsanHscm9000State1?.calSum === undefined ? "0" : gunsanHscm9000State1?.calSum}`}
            deviceName="군산 적산열량계"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="순간유량 m3/h"
            data={`${gunsanHscm9000State1?.flowIns === undefined ? "0" : gunsanHscm9000State1?.flowIns}`}
            deviceName="군산 적산열량계"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="적산유량 m3"
            data={`${gunsanHscm9000State1?.flowSum === undefined ? "0" : gunsanHscm9000State1?.flowSum}`}
            deviceName="군산 적산열량계"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="공급온도 C"
            data={`${gunsanHscm9000State1?.supplyTempIns === undefined ? "0" : gunsanHscm9000State1?.supplyTempIns}`}
            deviceName="군산 적산열량계"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="회수온도 C"
            data={`${gunsanHscm9000State1?.returnTempIns === undefined ? "0" : gunsanHscm9000State1?.returnTempIns}`}
            deviceName="군산 적산열량계"
          />
        </Grid>

        <Grid item>
          <SimpleCard
            title="유효전력량(+)"
            data={`${gunsanKdx300State?.power === undefined ? "0" : gunsanKdx300State?.power}`}
            deviceName="군산 전력량계"
          />
        </Grid>
      </Grid>
      <Divider sx={{marginBottom: 2, marginTop: 2}} />
      <Grid item xs={12} lg={12} mb={1}>
        <Alert icon={<Share2 />} severity="info">
          연결 상태
        </Alert>
      </Grid>
      <FlowBoard />
    </>
  )
}