import React from "react";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";

type LineChartProps = {
  title: string,
  xLabel: string,
  yLabel: string,
  series: ApexAxisChartSeries
  height?: number
  xFormatter?: (val: number) => string
  yFormatter?: (val: number) => string
}
export default function LineChart(props: LineChartProps) {
  const options: ApexOptions = {
    theme: {
      mode: 'dark',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: '#255aee',
        shadeTo: 'light',
        shadeIntensity: 0.65
      },
    },
    colors: ['#FFB74D'],
    chart: {
      foreColor: 'white',
      type: 'area',
      stacked: false,
      height: !props.height ? 400 : props.height,
      defaultLocale: 'ko',
      locales: [
        {
          name: 'ko',
          options: {
            months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
            shortMonths: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
            days: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
            shortDays: ['일', '월', '화', '수', '목', '금', '토'],
            toolbar: {
              selection: '선택모드',
              selectionZoom: '선택줌모드',
              zoomIn: '줌인',
              zoomOut: '줌아웃',
              pan: '이동모드',
              reset: '리셋',
            },
          },
        },
      ],
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
    },
    title: {
      text: props.title,
      align: 'left'
    },
    fill: {
      // type: 'gradient',
      // gradient: {
      //   shadeIntensity: 1,
      //   inverseColors: false,
      //   opacityFrom: 0.5,
      //   opacityTo: 0,
      //   stops: [0, 90, 100]
      // },
    },
    yaxis: {
      labels: {
        formatter: !props.yFormatter ? props.yFormatter : function (val) {
          return val.toString()
        }
      },
      title: {
        text: props.yLabel
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        datetimeUTC: false,
        datetimeFormatter: {
          year: 'yyyy',
          month: 'yy-MM',
          day: 'yyyy-MM-dd',
          hour: 'HH:mm',
        },
      }
    },
    tooltip: {
      theme: 'dark',
      shared: false,
      x: {
        format: 'dd MMM yyyy HH:mm:ss'
      },
      y: {
        formatter: !props.yFormatter ? props.yFormatter : function (val) {
          return val.toString()
        }
      }
    }
  }
  return (
    <ReactApexChart
      series={props.series}
      options={options}
    />
  )
}