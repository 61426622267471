import getAxiosTemplate from "../AxiosTemplate";
import {checkApiError} from "../../common/CommonUtil";
import {CommonResponseType, UserType} from "../../@types";
import {UserStorage} from "../../storage/UserStorage";

export default function LoginApi() {
  const BASE_URL = "/api/login"
  // const user = useRecoilValue(loginState);
  // const request = getAxiosTemplate(user?.token);
  const user = UserStorage.getUser()
  const request = getAxiosTemplate(user?.token);

  return {
    userLogin: async (id: String, pw: String) : Promise<CommonResponseType<UserType>> => {
      const param = {
        "id": id,
        "pw": pw
      }

      const res = await request.post<CommonResponseType<UserType>>(BASE_URL, param).catch(e => checkApiError<UserType>(e));
      if(!res) {
        throw Error("유저 로그인 실패")
      }

      return res.data
    },

    userRefresh: async () : Promise<CommonResponseType<UserType>> => {
      const res = await request.post<CommonResponseType<UserType>>(`${BASE_URL}/refresh`).catch(e => checkApiError<UserType>(e));
      if(!res) {
        throw Error("유저 로그인 실패")
      }

      return res.data
    }
  };
}