import Kdx300Api from "../../api/device/Kdx300Api";
import SearchButtonGroup from "../../component/board/SearchButtonGroup";
import {Box, Grid, Stack} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import React, {useEffect, useState} from "react";
import {ChartSeriesType, Kdx300Type, SearchDateTimeRangeType, WrapIdType, XyDataType} from "../../@types";
import {
  convertMoment,
  exportExcel,
  formatDate,
  getDateTimeRange,
  makeToast,
  wrapIdField
} from "../../common/CommonUtil";
import DefaultDataGrid from "../../component/board/DefaultDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import LineChart from "../../component/chart/LineChart";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../state";

export default function Kdx300Page(){
  const setLoading = useSetRecoilState(loadingState);
  const kdx300Api = Kdx300Api();
  const [dateTimeRange, setDateTimeRagne] = useState<SearchDateTimeRangeType>(getDateTimeRange('1d'))

  const [kdx300DataRows, setKdx300DataRows] = useState<WrapIdType<Kdx300Type>[]>([])
  const [powerDataset, setPowerDataset] = useState<ChartSeriesType>([])

  useEffect(() => {
    search()
  }, [])

  const kdx300DataColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'No',
      headerAlign: 'center',
      align: 'center',
      width: 80
    },
    {
      field: 'rtc',
      headerName: '시간',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
    {
      field: 'power',
      headerName: '전력량',
      headerAlign: 'center',
      align: 'center',
      width: 200
    },
  ]

  // 조회조건 이벤트
  const handleDateTimeOnChange = (target: "start" | "end", value: moment.Moment | null) => {
    if (value === null) {
      return
    }

    if (target === "start") {
      setDateTimeRagne({...dateTimeRange, start: value})
    } else {
      setDateTimeRagne({...dateTimeRange, end: value})
    }
  }

  const search = async () => {
    // Validation
    if (dateTimeRange.end.diff(dateTimeRange.start, 'second') <= 0) {
      makeToast("시작날짜를 확인해주세요.", 'error')
      return
    }

    setLoading({loading: true})
    const kdx300Res = await kdx300Api.findAllByTagCodeAndTimeRange(
      "LOTTE_GUNSAN_KDX300_1",
      convertMoment(dateTimeRange.start, "DATE_TIME"),
      convertMoment(dateTimeRange.end, "DATE_TIME"))

    if (kdx300Res.isError) {
      makeToast(kdx300Res.desc, "error")
      return
    }

    let kdx300DataRows = kdx300Res.data === null ? [] : kdx300Res.data

    kdx300DataRows.forEach((item) => {
      item.rtc = formatDate(item.rtc)
    })

    setKdx300DataRows(wrapIdField(kdx300DataRows))
    setKdx300ChartDataset(kdx300DataRows)
    setLoading({loading: false})
  }

  const setKdx300ChartDataset = (dataList: Kdx300Type[]) => {
    const powerDatasetArray:XyDataType[] = []

    dataList?.map((value, index) => {
      let rtc = new Date(value.rtc)

      powerDatasetArray.push({
        x: rtc,
        y: Number(value.power)
      })
    })

    setPowerDataset([{
      name: "전력량",
      data: powerDatasetArray
    }])
  }

  const reset = () => {
    setDateTimeRagne(getDateTimeRange('1d'))
  }

  return (
    <>
      {/*조회버튼*/}
      <SearchButtonGroup
        searchOnClickHandler={search}
        resetOnClickHandler={reset}
        excelOnClickHandler={() => exportExcel<Kdx300Type>(kdx300DataRows, "kdx300")}
      />

      {/*검색조건*/}
      <Box mb={4}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={1} direction="row">
              <DateTimePicker
                label="Start Date Time"
                ampm={false}
                value={dateTimeRange.start}
                onChange={(v) => handleDateTimeOnChange("start", v)}
              />
              <DateTimePicker
                label="End Date Time"
                ampm={false}
                value={dateTimeRange.end}
                onChange={(v) => handleDateTimeOnChange("end", v)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      {/*차트*/}
      <Box mb={4}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} sm={6} width={'100%'}>
            <LineChart
              title="전력량"
              xLabel="DateTime"
              yLabel="Kw/h"
              series={powerDataset}
            />
          </Grid>
        </Grid>
      </Box>

      {/*그리드*/}
      <DefaultDataGrid
        columns={kdx300DataColumns}
        rows={kdx300DataRows}
      />
    </>
  )
}
