import getAxiosTemplate from "../AxiosTemplate";
import {CommonResponseType, Kdx300Type, TimeRangeType} from "../../@types";
import {checkApiError} from "../../common/CommonUtil";
import {UserStorage} from "../../storage/UserStorage";

export default function Kdx300Api() {
  const BASE_URL = "/api/device/kdx300"

  // const user = useRecoilValue(loginState);
  // const request = getAxiosTemplate(user?.token);
  const user = UserStorage.getUser()
  const request = getAxiosTemplate(user?.token);

  return {
    findAllTagCodeAndTimeRangeCode: async (tagCode: string, range: TimeRangeType) : Promise<CommonResponseType<Kdx300Type[]>> => {
      const res = await request.get<CommonResponseType<Kdx300Type[]>>(`${BASE_URL}/${tagCode}/${range}`)
        .catch(e => checkApiError<Kdx300Type[]>(e))
      if(!res) {
        throw Error("KDX300 데이터 조회 실패")
      }

      return res.data
    },

    findAllByTagCodeAndTimeRange: async (tagCode: string, startDateTime: string, endDateTime: string) : Promise<CommonResponseType<Kdx300Type[]>> => {
      const res = await request.get<CommonResponseType<Kdx300Type[]>>(`${BASE_URL}/${tagCode}/${startDateTime}/${endDateTime}`)
        .catch(e => checkApiError<Kdx300Type[]>(e))

      if(!res) {
        throw Error("KDX300 데이터 조회 실패")
      }

      return res.data
    },
  }
}