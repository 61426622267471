import {atom} from "recoil";
import {LoadingType, ThemeType, UserType} from "../@types";

export const loginState = atom<UserType | null>({
  key: 'loginState',
  default: null,
});

export const loadingState = atom<LoadingType>({
  key: 'loadingState',
  default: {
    loading: false,
  },
});